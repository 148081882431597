import { registerFunctionComponent } from '/js/lib/webact.js';

function AppHeader() {
  const { html, css, $, postRender } = this;

  html`
    <button>
      <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
        <path d="M0 5.5h15m-15-4h15m-15 8h15m-15 4h15" stroke="currentColor"></path>
      </svg>
    </button>
    <div id="site-title">
      <nattramn-link href="/">Podd-App</nattramn-link>
    </div>
  `;

  css`
    :host {
      --background-color: #FFF;
      --border-color: #DEDEDE;
      height: 50px;
      position: sticky;
      top: 0;
      background-color: var(--background-color);
      border-bottom: 1px solid var(--border-color);
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      z-index: 1;
    }

    @media (prefers-color-scheme: dark) {
      :host {
        --background-color: #1E1E1E;
        --border-color: #343434;
      }
    }

    #site-title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 50px;
    }

    svg {
      fill: currentColor;
      width: calc(var(--gr-rem) / 2);
      height: calc(var(--gr-rem) / 2);
    }

    button {
      all: unset;
      position: absolute;
      top: 0;
      left: 0;
      height: 50px;
      cursor: pointer;
      padding: 0 1rem;
      transform: translateX(-2px);
    }

    @media (min-width: 984px) {
      button {
        display: none;
      }
    }
  `;

  postRender(() => {
    $('button').addEventListener('click', () => {
      document.dispatchEvent(new CustomEvent('side-menu:toggle'));
    });
  });
}

export default registerFunctionComponent(AppHeader, {
  name: 'app-header'
});
